<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <g>
      <path
        style="fill:#FED159;"
        d="M152.967,188.611c0,0-78.922,62.561-50.048,164.582c0,0,49.609-52.359,106.029-37.277"
      />
      <path
        style="fill:#FED159;"
        d="M359.033,188.611c0,0,78.922,62.561,50.048,164.582c0,0-49.609-52.359-106.029-37.277"
      />
    </g>
    <path
      style="fill:#82DCC7;"
      d="M255.999,0c-60.681,26.808-103.034,118.008-103.034,188.611c0,46.332,18.239,88.401,47.928,119.425
	c30.03,31.38,80.183,31.38,110.212,0c29.689-31.025,47.928-73.093,47.928-119.425C359.033,118.008,316.68,26.808,255.999,0z"
    />
    <path
      style="fill:#74CBB4;"
      d="M217.719,308.036c-20.623-31.025-33.295-73.093-33.295-119.425
	c0-70.603,29.422-161.803,71.575-188.611c-60.681,26.808-103.034,118.008-103.034,188.611c0,46.332,18.239,88.401,47.928,119.425
	c15.015,15.69,35.06,23.535,55.106,23.535C242.074,331.571,228.15,323.726,217.719,308.036z"
    />
    <path
      style="fill:#F64B4A;"
      d="M255.999,512c29.225-12.911,49.623-56.835,49.623-90.839c0-22.315-8.785-42.575-23.083-57.518
	c-14.462-15.113-38.617-15.113-53.081,0c-14.298,14.942-23.083,35.203-23.083,57.518C206.377,455.165,226.775,499.089,255.999,512z"
    />
    <g>
      <path
        style="fill:#FED159;"
        d="M255.999,440.811c14.055-6.209,23.864-27.332,23.864-43.685c0-10.731-4.225-20.475-11.1-27.66
		c-6.955-7.269-18.571-7.269-25.526,0c-6.876,7.185-11.1,16.929-11.1,27.66C232.136,413.479,241.946,434.602,255.999,440.811z"
      />
      <polygon
        style="fill:#FED159;"
        points="297.371,147.52 297.371,130.974 266.615,130.974 266.615,109.659 250.068,109.659 
		250.068,130.974 219.311,130.974 219.311,184.821 280.824,184.821 280.824,205.576 219.311,205.576 219.311,222.123 
		250.068,222.123 250.068,243.437 266.615,243.437 266.615,222.123 297.371,222.123 297.371,168.274 235.857,168.274 
		235.857,147.52 	"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
